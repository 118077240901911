<template>
  <div>
    <v-card :loading="isLoading" class="mb-2">
      <v-app-bar flat v-if="can('edit-house-income')">
        <v-btn
          @click="handleCreateMissingClick"
          class="btn"
          color="pink"
          outlined
          small
          :loading="isLoading"
          v-if="can('edit-accounting')"
        >
          Eksik Hesapları Oluştur
        </v-btn>

        <v-btn
          @click="handleTemplateDownloadClick"
          class="btn ms-2"
          color="pink"
          outlined
          small
          :loading="isLoading"
          v-if="can('edit-accounting')"
        >
          XLSX şablon indir
        </v-btn>

        <v-btn
          @click="handleTemplateUploadClick"
          class="btn ms-2"
          color="pink"
          outlined
          small
          :loading="isLoading"
          v-if="can('edit-accounting')"
        >
          XLSX şablon yükle
        </v-btn>

        <rs-file
          label="Dosya"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.oasis.opendocument.spreadsheet"
          @change="handleFileChange"
          class="d-none"
          id="importInput"
        />
      </v-app-bar>

      <!-- <v-card-title>
        <v-icon class="mr-2">mdi-account-cash-outline</v-icon>
        Muhasebe Hesapları
      </v-card-title> -->

      <v-card-text v-if="clusterId">
        <v-form @submit.prevent="handleFormSubmit" ref="form">
          <v-row dense class="mt-5">
            <v-col>
              <v-data-table
                v-bind="dataTableAttrs"
                :headers="headersShown"
                :items="list"
                :loading="isLoading"
                :options.sync="options"
                :server-items-length="total"
                :items-per-page.sync="itemsPerPage"
                @update:items-per-page="handleItemsPerPageUpdated"
                :show-select="false"
                :item-class="houseRowClass"
              >
                <template v-slot:top>
                  <rs-table-title-bar
                    v-if="$refs.headSearch"
                    title="Muhasebe Hesapları"
                    icon="mdi-account-cash-outline"
                    @clear-filters="$refs.headSearch.reset"
                    @reload="loadList"
                    hide-edit
                    :search.sync="search.query"
                    v-bind="titleBarAttrs"
                    :delete-enabled="selectedItems.length === 1"
                    @click:delete="handleDeleteClick"
                    :show-delete="can('delete-budget')"
                  >
                  </rs-table-title-bar>
                </template>

                <!-- eslint-disable-next-line -->
                <template v-slot:body.prepend="{ headers }">
                  <rs-table-head-search
                    :headers="headers"
                    :search.sync="search"
                    ref="headSearch"
                  />
                </template>

                <!-- eslint-disable-next-line -->
                <template v-slot:item.code="{ item, index }">
                  <rs-text-field
                    label="Hesap Kodu"
                    v-model="item.code"
                    :rules="
                      item.disabled
                        ? []
                        : [
                            rules.required,
                            rules.minLength(item.code, 3),
                            rules.maxLength(item.code, 50),
                          ]
                    "
                    :disabled="
                      item.disabled || item.isLoading || !can('edit-accounting')
                    "
                    @blur="handleCodeBlur(item)"
                  />
                  <!-- @paste="handleAmountPaste($event, index)" -->
                </template>

                <!-- eslint-disable-next-line -->
                <template v-slot:item.description="{ item, index }">
                  <rs-text-field
                    label="Açıklama"
                    v-model="item.description"
                    :rules="
                      item.disabled
                        ? []
                        : [
                            rules.required,
                            rules.minLength(item.description, 1),
                            rules.maxLength(item.description, 200),
                          ]
                    "
                    :disabled="
                      item.disabled || item.isLoading || !can('edit-accounting')
                    "
                  />
                  <!-- @paste="handleAmountPaste($event, index)" -->
                </template>

                <!-- eslint-disable-next-line -->
                <template v-slot:item.name="{ item }">
                  <template v-if="item.type === 'bank_account'">
                    <router-link
                      :to="{
                        name: 'definitions.bank-accounts.edit',
                        params: { id: item.accountable_id },
                      }"
                      v-if="can('see-bank-account')"
                      >{{ item.bank_account_name }}</router-link
                    >
                    <template v-else>{{ item.bank_account_name }}</template>
                  </template>

                  <template v-else-if="item.type === 'safe'">
                    <router-link
                      :to="{
                        name: 'definitions.safes.edit',
                        params: { id: item.accountable_id },
                      }"
                      v-if="can('see-safe')"
                      >{{ item.safe_name }}</router-link
                    >
                    <template v-else>{{ item.safe_name }}</template>
                  </template>

                  <template v-else-if="item.type === 'provider'">
                    <router-link
                      :to="{
                        name: 'definitions.cluster-providers.edit',
                        params: { id: item.accountable_id },
                      }"
                      v-if="can('see-provider')"
                      >{{ item.provider_name }}</router-link
                    >
                    <template v-else>{{ item.provider_name }}</template>
                  </template>

                  <template v-else-if="item.type === 'house'">
                    <router-link
                      :to="{
                        name: 'definitions.houses.edit',
                        params: { id: item.accountable_id },
                      }"
                      v-if="can('see-house')"
                      >{{ item.house_name }}</router-link
                    >
                    <template v-else>{{ item.house_name }}</template>
                  </template>

                  <template v-else-if="item.type === 'house_user'">
                    <router-link
                      :to="{
                        name: 'definitions.residents.edit',
                        params: { id: item.resident_id },
                      }"
                      v-if="can('see-resident')"
                      >{{ item.house_user_name }}</router-link
                    >
                    <template v-else>{{ item.house_user_name }}</template>
                  </template>

                  <template v-else-if="item.type === 'income_type'">
                    <router-link
                      :to="{
                        name: 'other-definitions.income-types.edit',
                        params: { id: item.accountable_id },
                      }"
                      v-if="can('edit-income-type')"
                      >{{ item.income_type_name }}</router-link
                    >
                    <template v-else>{{ item.income_type_name }}</template>
                  </template>

                  <template v-else-if="item.type === 'expense_type'">
                    <router-link
                      :to="{
                        name: 'other-definitions.expense-types.edit',
                        params: { id: item.accountable_id },
                      }"
                      v-if="can('edit-expense-type')"
                      >{{ item.expense_type_name }}</router-link
                    >
                    <template v-else>{{ item.expense_type_name }}</template>
                  </template>

                  <template v-else-if="item.type === 'other_income_type'">
                    <router-link
                      :to="{
                        name: 'other-definitions.other-incomes.edit',
                        params: { id: item.accountable_id },
                      }"
                      v-if="can('edit-other-income-type')"
                      >{{ item.expense_type_name }}</router-link
                    >
                    <template v-else>{{ item.expense_type_name }}</template>
                  </template>

                  <template v-else-if="item.type === 'block'">
                    {{ item.block_name }}
                  </template>

                  <template v-else-if="item.type === 'other_expense_type'">
                    {{ item.income_type_name }}
                  </template>
                </template>

                <!-- eslint-disable-next-line -->
                <!-- <template v-slot:item.activate="{ item }">
                  <v-btn
                    @click="handleHouseToggle(item)"
                    class="btn"
                    color="pink"
                    outlined
                    small
                  >
                    {{ item.disabled ? "Etkinleştir" : "İptal Et" }}
                  </v-btn>
                </template> -->

                <!-- eslint-disable-next-line -->
                <!-- <template v-slot:header.activate>
                  <v-btn
                    @click="handleToggleAllHouses()"
                    class="btn"
                    color="pink"
                    outlined
                    small
                  >
                    {{
                      houses.filter((item) => !item.disabled).length
                        ? "Tümünü İptal Et"
                        : "Tümünü Etkinleştir"
                    }}
                  </v-btn>
                </template> -->
              </v-data-table>
            </v-col>
          </v-row>

          <rs-form-buttons
            :isLoading="isLoading"
            @cancel="handleCancelClick"
            @submit="handleFormSubmit"
            hide-submit
          />
        </v-form>
      </v-card-text>

      <v-card-text v-else>
        Devir alacak girebilmek için üst menüden toplu yaşam alanı seçin.
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import {
  filtersToURL,
  hasExport,
  hasForm,
  hasPermissions,
} from "@/view/mixins";

export default {
  mixins: [filtersToURL, hasExport, hasForm, hasPermissions],
  computed: {
    ...mapGetters(["blocks", "cluster", "clusterId", "houseTypes"]),
  },
  data() {
    return {
      showAmounts: true,
      dataTableAttrs: {
        footerProps: {
          disableItemsPerPage: false,
        },
      },
      options: {
        sortBy: ["code"],
      },
      headers: [
        {
          text: "Hesap Türü",
          value: "type_name",
          searchable: false,
          // options: () => this.blocks,
          width: "120px",
        },
        {
          text: "Hesap İlişki",
          value: "name",
          searchable: "text",
          width: "75px",
          align: "center",
        },
        {
          text: "Hesap Kodu",
          value: "code",
          searchable: "text",
          align: "center",
        },
        {
          text: "Açıklama",
          value: "description",
          searchable: "text",
          align: "center",
        },
      ],
      titleBarAttrs: {
        exportUrl: () => "accounting/accounts",
        exportParams: this.getParams,
      },
    };
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.cluster_id = this.clusterId;

      return params;
    },
    loadList() {
      if (!this.clusterId) {
        return false;
      }

      this.isLoading = true;
      this.list = [];
      this.footTotals = [];

      const params = this.getParams();

      this.$api
        .query(`accounting/accounts`, { params })
        .then((response) => {
          this.loadListFromResponse(response);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleCreateMissingClick() {
      if (this.isLoading) {
        return false;
      }

      const formData = {
        cluster_id: this.clusterId,
      };

      this.isLoading = true;

      this.$api
        .post("accounting/accounts/create-missing", formData)
        .then((response) => {
          response;

          this.isLoading = false;
          return this.loadList();
        })
        .catch((error) => {
          this.handleError(error);
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      const formData = { cluster_id: this.clusterId };

      formData.houses = this.houses.filter((item) => {
        return !item.disabled;
      });

      if (formData.houses.length === 0) {
        this.$toast.error("En az bir bağımsız bölüm aktif olmalı.");
        return false;
      }

      this.isLoading = true;

      if (formData.calculation_type_id === 1) {
        delete formData.block_ids;
      }

      this.$api
        .post(`income/batch-transfer-due`, formData)
        .then(() => {
          this.$toast.success("Kaydedildi");

          if (event && event.closeOnSave) {
            this.handleCancelClick();
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleCodeBlur(item) {
      if (item.isLoading) {
        return false;
      }

      const formData = {
        code: item.code,
        description: item.description,
      };

      this.$api
        .put(`accounting/accounts/${item.id}`, formData)
        .then(() => {
          this.$toast.success(this.$t("saved"));
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleTemplateDownloadClick() {
      if (this.isLoading) return false;
      this.isLoading = true;

      const params = {
        cluster_id: this.clusterId,
      };

      this.$api
        .post("accounting/accounts/import-template", params)
        .then((response) => {
          const anchor = document.createElement("a");
          anchor.href = response.data.data.url;
          anchor.target = "_blank";
          // anchor.download =
          //   response.data.data.title + "." + response.data.data.extension;
          anchor.click();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleTemplateUploadClick() {
      document.querySelector("input#importInput").value = null;
      document.querySelector("input#importInput").click();
    },
    handleFileChange(file) {
      if (!file) {
        return;
      }

      const allowedMimes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.oasis.opendocument.spreadsheet",
      ];

      if (allowedMimes.indexOf(file.type) === -1) {
        this.$toast.error("Sadece XLSX veya ODS yükleyebilirsiniz.");
        return;
      }

      this.isLoading = true;
      const data = new FormData();
      data.append("file", file);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      this.$api
        .post("read-spreadsheet", data, config)
        .then((response) => {
          const updateData = {
            cluster_id: this.clusterId,
            list: [],
          };
          for (const index in response.data.data) {
            const row = response.data.data[index];
            updateData.list.push({
              id: parseInt(row[0]),
              code: row[3],
              description: row[4],
            });
          }

          return updateData;
        })
        .catch((error) => {
          this.handleError(error);
        })
        .then((updateData) => {
          return this.$api.post("accounting/accounts/import", updateData);
        })
        .then(() => {
          this.$toast.success(this.$t("saved"));
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        })
        .then(() => {
          return this.loadList();
        });
    },
    handlePaginationUpdated() {
      this.showAmounts = false;
      setTimeout(() => (this.showAmounts = true), 50);
    },
    houseRowClass(item) {
      return item.disabled ? "disabled" : null;
    },
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }, 250),
      deep: true,
    },
    clusterId() {
      this.loadList();
    },
  },
  mounted() {
    this.generateExportColumns(this.headers);
  },
};
</script>
